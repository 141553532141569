// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
import 'slick-carousel';
import AOS from 'aos';
import 'owl.carousel';
window.jQuery = $;
window.$ = $;
require("bootstrap");
require("./jquery.ebcaptcha.js");
require("./mixitup.js");
require("./mixitup-pagination.js");
require("./input-file.js");

// Remove this demo code, that's only here to show how the .env file works!

$(function () {
  AOS.init();

  $('.nav-main .navbar-toggler').click(function () {
    $(this).toggleClass('open');
  });
  //Adding fixed position to header
  $(document).scroll(function () {
    if ($(document).scrollTop() >= 1) {
      $('.topnav').addClass('fixed-nav-top');
      $('html').addClass('has-fixed-nav');

    } else {
      $('.topnav').removeClass('fixed-nav-top');
      $('html').removeClass('has-fixed-nav');

    }

  });

  $('.carousel-home-').slick({
    autoplay: true,
  });

  $('.posts-home').slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: 50,
          arrows: false,
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 1
        }
      }
    ]
  });

  $('.owl-univer').owlCarousel({
    loop: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      600: {
        items: 2,
        dots: true,
      },
      1000: {
        items: 4,
        nav: true,
        loop: false
      }
    }
  });
  //articles
  $('.articles-entreprise').owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      600: {
        items: 2,
        dots: true,
      },
      1000: {
        items: 3,
        nav: false,
        loop: false
      }
    }
  });
  //services  
  $('.caroussel-equipeservices').owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      600: {
        items: 2,
        dots: true,
      },
      1000: {
        items: 4,
        nav: false,
        loop: false,
        dots: false,
      }
    }
  });

  if (screen.width < 767) {
    $('.service-texte').removeClass('order-last');
  }
  //maps
  $(".map-pointer").click(function (e) {
    $(this).addClass("Mcurrent").siblings().removeClass("Mcurrent");
  });
  //hover pointer
  $(".all-pointer .map-pointer").click(function (e) {
    e.preventDefault();
    $(this).siblings('g.Mcurrent').removeClass("Mcurrent");
    $(this).addClass("Mcurrent");
    var index = $(this).index();

    $(".float-contact>.all-adress .box-adress").removeClass("active");
    $(".float-contact>.all-adress .box-adress").eq(index).addClass("active");

  });
  //hover adress
  $(".all-adress .box-adress").hover(function (e) {
    e.preventDefault();
    $(this).siblings('div.active').removeClass("active");
    $(this).addClass("active");
    var index = $(this).index();

    $(".all-pointer>.map-pointer").removeClass("Mcurrent");
    $(".all-pointer>.map-pointer").eq(index).addClass("Mcurrent");



  });
  var top_offset = 115;
  if ($(window).width() < 768) {
    var top_offset = $('header.topnav').height() - 1;
  }
  $('.nav-main a').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - top_offset
        }, 1000);
        return false;
      }
    }
  });

  $('.btn-arrow').click(function (e) {
    e.preventDefault();
    $(this).toggleClass(active);
  });

  //Close navbar on click
  $('.nav-main ul > li > a').click(function () {
    if ($(window).width() < 768) {
      $(".navbar-toggler").click();
    }
  });

  $('#contact-us').ebcaptcha();

  //home services


  $('.service-texte-inner').prepend('<span class="nav-click"><i class="nav-arrow"></i></span>');
  $('.service-texte-inner').on('click', '.nav-click', function () {

    $(this).siblings('.contentservice').slideToggle();
    $(this).children('.nav-arrow').toggleClass('nav-rotate');

  });
  //$('.card:first-child .collapse').addClass('show');
  //actualiter filter
  if ($('.block-articles').length > 0) {
    var containerEl = document.querySelector('.block-articles');

    var mixer = mixitup(containerEl, {
      pagination: {
        limit: 6 // impose a limit of 8 targets per page
      },
      animation: {
        animateResizeTargets: true
      }
    });

  }

// This works but not with window resize
if( $(".infos-slider").length > 0){
  var stiky = $(".infos-slider");
  var content = $(".nos-sites").offset();

  $(window).scroll(function(){
       var screenPosition = $(document).scrollTop() + 120;
        if (screenPosition < content.top) {
          stiky.removeClass( "stikyinfos" );
        }
        if (screenPosition >= content.top) {
            stiky.addClass("stikyinfos");
        }
    }); 
}


});



